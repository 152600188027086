<template>
  <div>
  <header id="header" class="fixed-top d-flex align-items-center">
      <div class="container d-flex align-items-center">
        <h1 class="logo me-auto"><a href="/">
          <img src="logo.png"></a></h1>
        <nav id="navbar" class="navbar order-last order-lg-0">
          <ul>
            <li><a class="nav-link active" href="/">Home</a></li>
            <li><a class="nav-link" href="/#/about">About</a></li>
            <li><a class="nav-link" href="/#/origin">origin</a></li>
            <li class="dropdown"><a href="/#/services"><span>Services</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li><a href="#">Vessel Agency</a></li>
                <li><a href="/#/cfs">Container Freight Station</a></li>
                <li><a href="#">Container Terminal</a></li>
                <li><a href="#">Equipment Maintainance &amp; Repair</a></li>
                <li><a href="#">Domestic Container Handling</a></li>
                <li><a href="#">Bulk Cargo Handling</a></li>
              </ul>
            </li>
          <li><a class="nav-link" href="/#/newsletter">News Letter</a></li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->

        <a href="/#/cfs-tariff" class="get-started-btn">CFS Tariff</a>
      </div>
    </header><!-- End Header -->
  </div>
</template>

<script>
export default ({
  name: 'HeaderPage'
})
</script>