<template>
	<div>
		<HeaderPage/>
	<main>
	<section class="breadcrumbs">
      <div class="container">

        <ol>
          <li>Home</li>
          <li>Services</li>
        </ol>
        <h2>Vilsons Group Services</h2>

      </div>
    </section>
    <section id="blog" class="blog">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-12 entries">
            <article class="entry">
                <div class="entry-title">
                    <h2><a href="/">Handling of Cargo Ships</a></h2><br/>
                </div>
                <div class="entry-img">
                    <img src="assets/img/services/bulkcargo.jpeg" alt="" class="img-fluid">
                </div>
                <div class="entry-content">
                    <p>1.50 Million Tonnes of Cargo Handled Per Annum, Coal, Rock Phosphate, Sulphur, Grain, Sulphuric Acid, Phosphoric Acid, Ammonia, Furnace oil, Heavy lift &amp; ODC.</p>
                    <div class="read-more">
                        <a href="/">Read More</a>
                    </div>
                </div>
            </article>

            <article class="entry">
                <div class="entry-title">
                    <h2><a href="/#/cfs">Custom Broking &amp; Warehousing</a></h2><br/>
                </div>
                <div class="entry-img">
                    <img src="assets/img/services/warehouse.jpg" alt="" class="img-fluid">
                </div>
                <div class="entry-content">
                    <p>Licensed Custom Broker, Custom Bonded Warehouse Operator</p>
                    <div class="read-more">
                        <a href="/#/cfs">Read More</a>
                    </div>
                </div>
            </article>

            <article class="entry">
                <div class="entry-title">
                    <h2><a href="/#/cfs">CFS &amp; Empty Container Terminal</a></h2><br/>
                </div>
                <div class="entry-img">
                    <img src="assets/img/services/empty_yard.jpg" alt="" class="img-fluid">
                </div>
                <div class="entry-content">
                    <p>Our Facility is licensed by Government of India and services Export House, Traders, Merchandiser, Industries an Import/Export of Container Cargo Facilitating Cargo Examination, Warehousing. Our Empty Container Terminal serves major Container Liners in Container Storage, EMR</p>
                    <div class="read-more">
                        <a href="/#/cfs">Read More</a>
                    </div>
                </div>
            </article>

            <article class="entry">
                <div class="entry-title">
                    <h2><a href="/">Hinterland Trucking &amp; Coastal Shipping</a></h2><br/>
                </div>
                <div class="entry-img">
                    <img src="assets/img/services/trucking.jpeg" alt="" class="img-fluid">
                </div>
                <div class="entry-content">
                    <p>Our Fleet of Trucks &amp; Trailers move Cargoes & Containers in-land to Customer premises. Our Fleet is augmented by market trucks based on respective project capacity. We own about 40 vehicles and 150 market trucks for cargo movement from VOC Port, Tuticorin. Seamless movement of Cargo on Digital Platform are value added service provided to EXIM Trade.</p>
                    <div class="read-more">
                        <a href="/">Read More</a>
                    </div>
                </div>
            </article>

            <article class="entry">
                <div class="entry-title">
                    <h2><a href="/">Shipping Support Services</a></h2><br/>
                </div>
                <div class="entry-img">
                    <img src="assets/img/services/projects.jpeg" alt="" class="img-fluid">
                </div>
                <div class="entry-content">
                    <p>We undertake a range of Material Handling Operations on Turnkey basis, In-Plant for Fertilizers, Power Projects, etc. The Firm offers Consultancy Services to EXIM Trade and International Business on DGFT, EXIM Policy, Project Cargo Survey, Data Management, Customs / GST Policies &amp; Procedures.</p>
                    <div class="read-more">
                        <a href="/">Read More</a>
                    </div>
                </div>
            </article>
            </div>
        </div>
    </div>
    </section>

  </main>
	</div>
</template>


<script>
import HeaderPage from '@/components/Header.vue';
export default {
  name: 'Servicespage',
  components: {
    HeaderPage,
  },
}
</script>